export const MapNames: { [key: string]: number } = {
    "sp_a1_intro1": 1,
    "sp_a1_intro2": 2,
    "sp_a1_intro3": 3,
    "sp_a1_intro4": 4,
    "sp_a1_intro5": 5,
    "sp_a1_intro6": 6,
    "sp_a1_intro7": 7,
    "sp_a1_wakeup": 8,
    "sp_a2_intro": 9,

    "sp_a2_laser_intro": 10,
    "sp_a2_laser_stairs": 11,
    "sp_a2_dual_lasers": 12,
    "sp_a2_laser_over_goo": 13,
    "sp_a2_catapult_intro": 14,
    "sp_a2_trust_fling": 15,
    "sp_a2_pit_flings": 16,
    "sp_a2_fizzler_intro": 17,

    "sp_a2_sphere_peek": 18,
    "sp_a2_ricochet": 19,
    "sp_a2_bridge_intro": 20,
    "sp_a2_bridge_the_gap": 21,
    "sp_a2_turret_intro": 22,
    "sp_a2_laser_relays": 23,
    "sp_a2_turret_blocker": 24,
    "sp_a2_laser_vs_turret": 25,
    "sp_a2_pull_the_rug": 26,

    "sp_a2_column_blocker": 27,
    "sp_a2_laser_chaining": 28,
    "sp_a2_triple_laser": 29,
    "sp_a2_bts1": 30,
    "sp_a2_bts2": 31,

    "sp_a2_bts3": 32,
    "sp_a2_bts4": 33,
    "sp_a2_bts5": 34,
    "sp_a2_core": 35,

    "sp_a3_01": 36,
    "sp_a3_03": 37,
    "sp_a3_jump_intro": 38,
    "sp_a3_bomb_flings": 39,
    "sp_a3_crazy_box": 40,
    "sp_a3_transition01": 41,

    "sp_a3_speed_ramp": 42,
    "sp_a3_speed_flings": 43,
    "sp_a3_portal_intro": 44,
    "sp_a3_end": 45,

    "sp_a4_intro": 46,
    "sp_a4_tb_intro": 47,
    "sp_a4_tb_trust_drop": 48,
    "sp_a4_tb_wall_button": 49,
    "sp_a4_tb_polarity": 50,
    "sp_a4_tb_catch": 51,
    "sp_a4_stop_the_box": 52,
    "sp_a4_laser_catapult": 53,
    "sp_a4_laser_platform": 54,
    "sp_a4_speed_tb_catch": 55,
    "sp_a4_jump_polarity": 56,

    "sp_a4_finale1": 57,
    "sp_a4_finale2": 58,
    "sp_a4_finale3": 59,
    "sp_a4_finale4": 60,

    "mp_coop_start": 61,
    "mp_coop_lobby_3": 62,

    "mp_coop_doors": 63,
    "mp_coop_race_2": 64,
    "mp_coop_laser_2": 65,
    "mp_coop_rat_maze": 66,
    "mp_coop_laser_crusher": 67,
    "mp_coop_teambts": 68,

    "mp_coop_fling_3": 69,
    "mp_coop_infinifling_train": 70,
    "mp_coop_come_along": 71,
    "mp_coop_fling_1": 72,
    "mp_coop_catapult_1": 73,
    "mp_coop_multifling_1": 74,
    "mp_coop_fling_crushers": 75,
    "mp_coop_fan": 76,

    "mp_coop_wall_intro": 77,
    "mp_coop_wall_2": 78,
    "mp_coop_catapult_wall_intro": 79,
    "mp_coop_wall_block": 80,
    "mp_coop_catapult_2": 81,
    "mp_coop_turret_walls": 82,
    "mp_coop_turret_ball": 83,
    "mp_coop_wall_5": 84,

    "mp_coop_tbeam_redirect": 85,
    "mp_coop_tbeam_drill": 86,
    "mp_coop_tbeam_catch_grind_1": 87,
    "mp_coop_tbeam_laser_1": 88,
    "mp_coop_tbeam_polarity": 89,
    "mp_coop_tbeam_polarity2": 90,
    "mp_coop_tbeam_polarity3": 91,
    "mp_coop_tbeam_maze": 92,
    "mp_coop_tbeam_end": 93,

    "mp_coop_paint_come_along": 94,
    "mp_coop_paint_redirect": 95,
    "mp_coop_paint_bridge": 96,
    "mp_coop_paint_walljumps": 97,
    "mp_coop_paint_speed_fling": 98,
    "mp_coop_paint_red_racer": 99,
    "mp_coop_paint_speed_catch": 100,
    "mp_coop_paint_longjump_intro": 101,

    "mp_coop_separation_1": 102,
    "mp_coop_tripleaxis": 103,
    "mp_coop_catapult_catch": 104,
    "mp_coop_2paints_1bridge": 105,
    "mp_coop_paint_conversion": 106,
    "mp_coop_bridge_catch": 107,
    "mp_coop_laser_tbeam": 108,
    "mp_coop_paint_rat_maze": 109,
    "mp_coop_paint_crazy_box": 110,
};
